#menu-profileType {
  z-index: 9999 !important;
}
.notification-message .title {
  color: white;
}
.btn-green {
  background-color :#1781AA;
  color: #fff;
  border: none;
  border-radius: 2px;
  padding: 0.5rem;
}

.btn-green:hover {
  opacity: 0.8;
}

.btn-transparent {
  background-color: transparent;
  color: #808080;
  border: 1px solid #808080;
  border-radius: 2px;
  padding: 0.5rem;
}

.btn-transparent:hover{
  opacity: 0.8;
}

a, button:hover {
  opacity: 0.8;
}

.MuiCircularProgress-svg{
  color: #1781aa
}
